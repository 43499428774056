import { useState } from 'react'
import { Input } from 'component-library'
import useForm from '../../hooks/useForm'
import GetInTouchSection from '../GetInTouchSection/GetInTouchSection'
import performance from '../../content/performance'

/* Form State */
type FormValues = {
  firstName: string
  lastName: string
  email: string
  affiliation: string
}

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  affiliation: '',
}

const GOOGLE_SHEET_URL =
  'https://script.google.com/macros/s/AKfycbw8sfu2eM02oQX5TKB_EoGkZLSub30aNjae9iu4OlowylPZRuE_xS2e7aISHOERVevo/exec'

const { contact_section } = performance

const PerformanceContactSection = () => {
  const FORM_NAME = 'performance-contact'
  const { values, handleChange, handleSubmit } = useForm<FormValues>({
    initialValues,
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [submittingForm, setSubmittingForm] = useState(false)
  const [showFormSubmissionError, setShowFormSubmissionError] = useState(false)

  const onSubmit = async (values: FormValues) => {
    const formData = new FormData()
    formData.append('firstName', values.firstName)
    formData.append('lastName', values.lastName)
    formData.append('affiliation', values.affiliation)
    formData.append('email', values.email)

    setShowFormSubmissionError(false)
    setSubmittingForm(true)

    const response = await fetch(GOOGLE_SHEET_URL, {
      method: 'POST',
      body: formData,
    })
    if (response && response.status === 200) {
      setIsSubmitted(true)
    } else {
      setShowFormSubmissionError(true)
    }
    setSubmittingForm(false)
  }

  return (
    <GetInTouchSection
      title={contact_section.title}
      description={contact_section.description}
      formName={FORM_NAME}
      isSubmitted={isSubmitted}
      submitButtonText={contact_section.submit_button.label}
      onSubmit={handleSubmit(onSubmit)}
      privacyText={contact_section.footnote}
      isSubmitting={submittingForm}
      showSubmissionError={showFormSubmissionError}
      errorMessage={contact_section.error_message}
      successTitle={contact_section.success_title}
      successDescription={contact_section.success_description}
    >
      <div className="grid grid-cols-2 gap-4 w-full">
        <Input
          className="col-span-2 md:col-span-1"
          fieldLabelHidden
          required
          disabled={submittingForm}
          formName={FORM_NAME}
          fieldName="firstName"
          placeholder="First name"
          fieldLabel="First name"
          updateValue={(value) => handleChange('firstName', value)}
          value={values?.firstName || ''}
        />
        <Input
          className="col-span-2 md:col-span-1"
          fieldLabelHidden
          required
          disabled={submittingForm}
          formName={FORM_NAME}
          fieldName="lastName"
          placeholder="Last name"
          fieldLabel="Last name"
          updateValue={(value) => handleChange('lastName', value)}
          value={values?.lastName || ''}
        />

        <Input
          className="col-span-2 md:col-span-1"
          fieldLabelHidden
          formName={FORM_NAME}
          disabled={submittingForm}
          fieldName="affiliation"
          placeholder="Affiliated institution"
          fieldLabel="Affiliated institution"
          updateValue={(value) => handleChange('affiliation', value)}
          value={values?.affiliation || ''}
        />

        <Input
          className="col-span-2 md:col-span-1"
          fieldLabelHidden
          type="email"
          required
          disabled={submittingForm}
          formName={FORM_NAME}
          fieldName="email"
          placeholder="Email"
          fieldLabel="Email"
          updateValue={(value) => handleChange('email', value)}
          value={values?.email || ''}
        />
      </div>
    </GetInTouchSection>
  )
}

export default PerformanceContactSection
