import { useRef, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { sendCustomGAEvent, useSetSlideLinkTabIndex } from 'shared-utilities'
import LinkComponent from '../LinkComponent/LinkComponent'
import { ICardLink } from '../../types'
import Image from '../Image/Image'
import MarkdownText from '../MarkdownText/MarkdownText'
import { getCurrentEnvConfig } from '../../../config'

import './style.scss'

// Needs to be put in a container to determine the height and width
const CardLink = ({
  link = '',
  icon,
  alt = '',
  title,
  description,
  className = '',
  linkText = '',
  simplePartnerCards = false,
  gaEvent,
  isCarouselSlide = false,
}: ICardLink) => {
  const cardRef = useRef<HTMLDivElement>(null)
  const [isVisible, toggleIsVisible] = useState<boolean>(!isCarouselSlide)
  const isGatsbyImage = !!(typeof icon !== 'string' && icon?.images)
  const isExternalImage = !!(
    typeof icon === 'string' && icon.startsWith('https://')
  )
  // Image source is from the setup-blogs script
  const isBlogStaticImage = !!(
    typeof icon === 'string' && icon.startsWith('/_images/blog/')
  )

  useSetSlideLinkTabIndex(cardRef, toggleIsVisible, isCarouselSlide)

  const content = (
    <div
      ref={cardRef}
      data-testid="card-container"
      className={`Link__card ${className} ${
        !link ? 'Link__card--disabled' : ''
      }`}
    >
      <div
        className={
          simplePartnerCards ? 'h-full flex items-center justify-center' : ''
        }
        data-testid="image-wrapper"
      >
        <div className="cardLink__image">
          {isGatsbyImage ? (
            <GatsbyImage
              data-testid="gatsby-link-icon"
              image={icon}
              alt={alt}
            />
          ) : (
            <Image
              data-testid="link-icon"
              alt={alt}
              src={
                isExternalImage || isBlogStaticImage ? icon : `/images/${icon}`
              }
            />
          )}
        </div>
      </div>
      {title && <h2 className="text-2xl whitespace-pre-line">{title}</h2>}
      {description && !simplePartnerCards && (
        <MarkdownText className="text-grey-3" text={description} />
      )}
      {linkText && (
        <span
          className={`Link__card__footer ${
            simplePartnerCards ? 'text-xl' : 'text-lg lg:py-4'
          }`}
          data-testid="link-card-footer"
        >
          {linkText}
          {!simplePartnerCards && (
            <i className="bx bx-chevron-right text-3xl"></i>
          )}
        </span>
      )}
    </div>
  )

  return link ? (
    <LinkComponent
      href={
        isBlogStaticImage ? `${getCurrentEnvConfig().siteURL}${link}` : link
      }
      openInCurrentTab={isBlogStaticImage}
      onClick={gaEvent ? () => sendCustomGAEvent('click', gaEvent) : undefined}
      tabIndex={isVisible ? 0 : -1}
    >
      {content}
    </LinkComponent>
  ) : (
    content
  )
}

export default CardLink
