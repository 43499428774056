import { useState, useCallback, FormEvent } from 'react'

type UseFormOptions<T> = {
  initialValues: T
}

function useForm<T>(options: UseFormOptions<T>) {
  const [values, setValues] = useState(options.initialValues)

  const handleChange = useCallback(
    (name: string, value: string | number | boolean) => {
      setValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }))
    },
    []
  )

  const handleSubmit = (onSubmit: (values: T) => void) =>
    useCallback(
      (e: FormEvent) => {
        e.preventDefault()
        onSubmit(values)
      },
      [values]
    )

  return {
    values,
    handleChange,
    handleSubmit,
  }
}

export default useForm
