import PerformanceHeroSection from '../../components/PerformanceHeroSection/PerformanceHeroSection'
import PerformancePartnersSection from '../../components/PerformancePartnersSection/PerformancePartnersSection'
import BuildWithPLSection from '../../components/BuildWithPennylaneSection/BuildWithPennylane'
import Seo from '../../components/seo'
import ProcessSection from '../../components/ProcessSection/ProcessSection'
import performance from '../../content/performance'
import './styles.scss'
import PerformanceNews from '../../components/PerformanceNews/PerformanceNews'
import CallToActionSection from '../../components/CallToActionSection/CallToActionSection'
import PerformanceContactSection from '../../components/PerformanceContactSection/PerformanceContactSection'

const {
  meta_title,
  meta_description,
  meta_image,
  get_started_cta,
  call_to_action_section,
  process_section,
} = performance

export const Head = () => {
  return (
    <Seo title={meta_title} description={meta_description} image={meta_image} />
  )
}

const Performance = () => {
  return (
    <>
      <PerformanceHeroSection />
      <PerformancePartnersSection />
      <CallToActionSection
        className="Performance__CallToActionSection"
        ctas={call_to_action_section}
      />
      <ProcessSection
        id={process_section.id}
        title={process_section.title}
        steps={process_section.steps}
        className="Performance__ProcessSection section-lg"
      />
      <PerformanceNews />
      <PerformanceContactSection />
      <BuildWithPLSection
        title={get_started_cta.title}
        buttons={get_started_cta.buttons}
        className="BuildWithPLSection--performance"
      />
    </>
  )
}

export default Performance
