import { StaticImage } from 'gatsby-plugin-image'
import SubSectionLayout from '../SubSectionLayout/SubSectionLayout'
import SpotlightSection from '../SpotlightSection/SpotlightSection'
import performance from '../../content/performance'
import { spotlights } from 'shared-content'
import './PerformanceHeroSection.scss'

const { title, description, button, image, link } = performance.performance_hero
const spotlight = spotlights.performance_page

const HERO_BACKGROUND = `https://assets.cloud.pennylane.ai/pennylane_website/backgrounds/confetti_transparent.png`

const PerformanceHeroSection = () => {
  return (
    <div className="Hero__Background__Container">
      <StaticImage
        src={HERO_BACKGROUND}
        alt=""
        loading="eager"
        placeholder="none"
        quality={100}
        objectPosition="top left"
        className="Hero__Background__Image Sticky__Image"
      />
      <div className="section-lg">
        <section
          className="PerformanceHero"
          data-testid="performance-hero-section"
        >
          <SubSectionLayout
            title={title}
            description={description}
            button_text={button.text}
            button_link={button.link}
            links={[link]}
            image={image}
            image_right_aligned
          />
        </section>

        {!spotlight.hidden && (
          <SpotlightSection announcements={spotlight.announcements} />
        )}
      </div>
    </div>
  )
}

export default PerformanceHeroSection
