import { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import performance from '../../content/performance'
import CardLink from '../CardLink/CardLink'
import './PerformanceNews.scss'

const { news_section } = performance

const PerformanceNews = () => {
  const data = useStaticQuery<Queries.PerformanceArticlesQuery>(query)
  const postSlides = useMemo(() => {
    return data.allMarkdownRemark.nodes.map((node) => {
      return {
        title: node?.frontmatter?.title,
        link: `/blog${node?.fields?.slug}`,
        logo: node?.frontmatter?.thumbnailImage?.childImageSharp
          ?.gatsbyImageData,
      }
    })
  }, [])

  return (
    <section className="PerformanceNews">
      <div className="section-lg flex flex-col gap-20 md:gap-10">
        <h2 className="text-3xl text-center">{news_section.title}</h2>
        <div className="PerformanceNews__Card__container">
          {postSlides.map((post) => (
            <CardLink
              className="PerformanceNews__Card"
              key={post.link}
              link={post.link}
              icon={post.logo}
              alt=""
              title={post.title || ''}
              linkText="Read more"
            />
          ))}
        </div>
      </div>
    </section>
  )
}

const query = graphql`
  query PerformanceArticles {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { tags: { in: "PERFORMANCE" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          description
          thumbnailImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default PerformanceNews
