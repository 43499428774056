import './GetInTouchSection.scss'

interface IGetInTouchSubmittedProps {
  title: string
  description: string
}

const GetInTouchSubmitted = ({
  title,
  description,
}: IGetInTouchSubmittedProps) => {
  return (
    <div className="GetInTouchSection__form--submitted">
      <i className="bx bx-check-circle" />
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  )
}

export default GetInTouchSubmitted
