import { FormEvent, ReactNode } from 'react'
import Image from '../Image/Image'
import { Button } from 'component-library'
import MarkdownText from '../MarkdownText/MarkdownText'
import GetInTouchSubmitted from './GetInTouchSubmitted'
import './GetInTouchSection.scss'

interface IGetInTouchProps {
  /** Marketing Content and Display Properties */
  backgroundImage?: string
  title: string
  description: string
  privacyText?: string

  /** Form Properties */
  formName: string
  children: ReactNode | ReactNode[]
  isSubmitted: boolean
  isSubmitting: boolean
  errorMessage?: string
  showSubmissionError: boolean
  submitButtonText?: string
  onSubmit: (e: FormEvent<HTMLFormElement>) => void
  successTitle?: string
  successDescription?: string

  /** Other */
  className?: string
}

const getInTouchIllustration =
  'https://assets.cloud.pennylane.ai/pennylane_website/icons/get_in_touch.png'

const GetInTouchSection = ({
  title,
  description,
  privacyText = '',
  formName,
  isSubmitted,
  isSubmitting,
  submitButtonText = 'Submit',
  onSubmit,
  showSubmissionError,
  errorMessage = 'Something went wrong. Please try again.',
  children: formFields,
  className = '',
  successDescription = 'A member of our team will be in touch with you shortly.',
  successTitle = 'Thank you!',
}: IGetInTouchProps) => {
  return (
    <section
      id="get-in-touch"
      className={`GetInTouchSection ${className}`}
      data-testid="get-in-touch-section"
    >
      <div className="GetInTouchSection__content section-lg">
        <div className="GetInTouchSection__container">
          <Image
            withoutPrefix
            src={getInTouchIllustration}
            className="GetInTouchSection__image"
            alt=""
          />
          <div className="GetInTouchSection__text">
            <h2 className="GetInTouchSection__title">{title}</h2>
            <p className="GetInTouchSection__description">{description}</p>
          </div>
        </div>

        <div className="w-full flex flex-col justify-center items-center md:w-1/2">
          <form
            onSubmit={onSubmit}
            name={formName}
            className="GetInTouchSection__form"
          >
            {isSubmitted ? (
              <GetInTouchSubmitted
                title={successTitle}
                description={successDescription}
              />
            ) : (
              <>
                {formFields}
                <div className="GetInTouchSection__form__button">
                  <Button
                    label={submitButtonText}
                    buttonType="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  />
                </div>
              </>
            )}
          </form>
          {showSubmissionError && (
            <div className="GetInTouchSection__form--error">
              <p>
                <i className="bx bxs-error-circle"></i>{' '}
                <span>{errorMessage}</span>
              </p>
            </div>
          )}
          {privacyText && !isSubmitted && (
            <MarkdownText
              text={privacyText}
              className="GetInTouchSection__privacy"
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default GetInTouchSection
