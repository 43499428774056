export default {
  meta_title: 'Performance — PennyLane',
  meta_description:
    'PennyLane works with all the latest CPU and GPU architectures, and is optimized for integration with high performance computing clusters, so you can get your research results faster.',
  meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/performance/hero/performance_page_hero.png',
  performance_hero: {
    title: 'Better performance, faster research.',
    description:
      'PennyLane works with all the latest CPU and GPU architectures, and is optimized for integration with high performance computing clusters, so you can get your research results faster.',
    image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/performance/hero/performance_page_hero.png',
    button: {
      text: 'Install PennyLane',
      link: '/install',
    },
    link: {
      link_text: 'Explore our simulators',
      link: '#which-simulator',
    },
  },
  partners_section: {
    title: 'Research and partnerships',
    /* Partner Cards - 
        - Requires 3 cards, as of now only 3 cards are supported. If there are more than 3 only the first 3 will be displayed.
        - If you want to hide this section, remove all the cards/comment them out.
        - Please restrict the card description to 100 characters including spaces. */
    partner_cards: [
      {
        link: '/blog/2023/04/pennylane-goes-kokkos-a-novel-hardware-agnostic-parallel-backend-for-quantum-simulations',
        description:
          "PennyLane's high performance lightning simulator works with and directly targets AMD's ROCm platform.",
        image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/performance/partners/amd_logo.png',
      },
      {
        link: '/blog/2022/07/lightning-fast-simulations-with-pennylane-and-the-nvidia-cuquantum-sdk',
        description:
          'Learn how NVIDIA works with PennyLane to enable GPU simulations at scale.',
        image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/performance/partners/nvidia_logo.png',
      },
      {
        link: 'https://pawsey.org.au/pawsey-and-xanadu-form-a-global-partnership-to-create-opportunities-for-australian-quantum-scientists/',
        description:
          'Pawsey and Xanadu are working together to integrate PennyLane, and provide state-of-the-art hybrid compute.',
        image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/performance/partners/pawsey_logo.png',
      },
    ],
  },
  call_to_action_section: [
    {
      title: 'Faster than lightning',
      description:
        'Lightning is a high-performance modern C++ simulator designed for all devices, both great (supercomputers) and small (laptops). Lightning comes included with PennyLane, and  supports a wide range of architectures — including x86, ARM, PPC — as well as features such as efficient computation of quantum gradients.',
      image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/performance/call_to_action/lightning.png',
      image_right_aligned: false,
      alt: '',
      links: [
        {
          link_text: 'See demos',
          link: 'https://pennylane.ai/search/?contentType=DEMO&categories=devices%20and%20performance',
        },
      ],
    },
    {
      title: 'GPU-enabled',
      description:
        'Need more oomph? Take advantage of GPU acceleration with lightning.gpu for NVIDIA CUDA platforms, and lightning.kokkos for AMD ROCm platforms. Built on top of the best tooling for each device, allowing users to compute quantum gradients over multiple GPUs with native accelerator device execution models.',
      image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/performance/call_to_action/gpu.png',
      image_right_aligned: true,
      alt: '',
      links: [
        {
          link_text: 'lightning.gpu',
          link: '/blog/2022/07/lightning-fast-simulations-with-pennylane-and-the-nvidia-cuquantum-sdk',
        },
        {
          link_text: 'lightning.kokkos',
          link: '/blog/2023/04/pennylane-goes-kokkos-a-novel-hardware-agnostic-parallel-backend-for-quantum-simulations',
        },
      ],
    },
    {
      title: 'Just-in-time compilation with Catalyst',
      description:
        'A complete rethinking of a quantum execution pipeline, Catalyst enables you to write hybrid quantum-classical programs that are automatically compiled for execution on CPUs, GPUs, and QPUs — leading to significant performance improvements, and the ability to unlock new ways of programming quantum computers.',
      image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/performance/call_to_action/catalyst.png',
      image_right_aligned: false,
      alt: '',
      links: [
        {
          link_text: 'Learn more',
          link: '/blog/2023/03/introducing-catalyst-quantum-just-in-time-compilation',
        },
      ],
    },
  ],
  news_section: {
    title: 'News and announcements',
  },
  get_started_cta: {
    title: 'Get started with PennyLane',
    /* Buttons -
        - Please limit button text to 25 characters including spaces.
    */
    buttons: [
      {
        text: 'Install PennyLane',
        link: '/install',
      },
      {
        text: 'Explore performance demos',
        link: '/search/?contentType=DEMO&categories=devices%20and%20performance',
      },
    ],
  },
  process_section: {
    /* 
      The `id` field serves as an identifier for enabling navigation via hash links on the page. 
      By default, the title of the section gets converted into a slug that serves as the ID. 
      If you're planning to specify a custom ID, make sure to follow proper URL syntax rules.

      eg. - hello world -> hello-world
    */
    id: 'which-simulator',
    title: 'Which simulator should I use?',
    // Descriptions use markdown syntax and must be enclosed in ``, e.g. `This is a description`
    steps: [
      {
        title: 'default.qubit',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/performance/process/default.qubit.png',
        description: `[<code>default.qubit</code>](https://docs.pennylane.ai/en/stable/introduction/circuits.html#defining-a-device) is PennyLane’s best all-round device that is perfect for fast prototyping with small-scale circuits.
- Statevector-based simulator.
- Built-in backpropagation support.
- Works with [just-in-time compilation](https://docs.pennylane.ai/en/stable/introduction/interfaces/jax.html#using-jax-jit-on-qnodes) using JAX.
- [Parallelize over circuit](https://docs.pennylane.ai/en/stable/code/api/pennylane.devices.default_qubit.DefaultQubit.html#accelerate-calculations-with-multiprocessing) runs using Python multithreading.
- Available on all operating systems.  

<br/>Ready to scale your simulations beyond 15 qubits? Check out PennyLane’s Lightning device ecosystem!
`,
      },
      {
        title: 'default.mixed',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/performance/process/default.qubit.png',
        description: `[<code>default.mixed</code>](https://pennylane.ai/qml/demos/tutorial_noisy_circuits/#noisy-operations) brings the capabilities of <code>default.qubit</code> to the mixed-state setting—perfect for simulations of noisy systems.
- Supports a range of commonly-used [quantum channels](https://docs.pennylane.ai/en/stable/introduction/operations.html#noisy-channels).
- Uses the density matrix representation internally.
- Built-in backpropagation support.
- Available on all operating systems.  

<br/>Note that mixed states are expensive to simulate! This device is best used with up to 15 qubits.
`,
      },
      {
        title: 'default.tensor',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/performance/process/default.qubit.png',
        description: `[<code>default.tensor</code>](https://docs.pennylane.ai/en/stable/code/api/pennylane.devices.default_tensor.DefaultTensor.html) uses a tensor network backend to accelerate simulations.
- Tensor network simulator using [<code>quimb</code>](https://quimb.readthedocs.io/en/latest/).
- Trade off between simulation accuracy and speed by adjusting the bond dimension.
- Supports both Matrix Product State (MPS) and exact Tensor Network (TN) methods.
- Available on all operating systems.

<br/>Try <code>lightning.tensor</code> when you want to scale up your circuits!
`,
      },
      {
        title: 'lightning.qubit',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/performance/process/lightning.qubit.png',
        description: `[<code>lightning.qubit</code>](https://docs.pennylane.ai/projects/lightning/en/stable/lightning_qubit/device.html) allows PennyLane to hook into highly-optimized C++ code to speed up your simulations.
- Statevector simulator with high-performance gate kernels.
- Fast gradients using the [adjoint differentiation method](https://pennylane.ai/qml/demos/tutorial_adjoint_diff/).
- Gradient calculations can be parallelized using OpenMP.
- Available on Windows, MacOS and Linux.  

<br/>Try <code>lightning.qubit</code> when you want to scale up your circuits!
`,
      },
      {
        title: 'lightning.kokkos',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/performance/process/lightning.kokkos.png',
        description: `[<code>lightning.kokkos</code>](https://docs.pennylane.ai/projects/lightning/en/stable/lightning_kokkos/device.html) gives you portability. Run on parallelized CPUs, NVIDIA GPUs, or AMD GPUs—whatever you have available!
- Statevector simulator using the [Kokkos](https://kokkos.org/) model.
- Fast gradients using the [adjoint differentiation method](https://pennylane.ai/qml/demos/tutorial_adjoint_diff/).
- [Distributed statevector support](https://pennylane.ai/blog/2023/04/pennylane-goes-kokkos-a-novel-hardware-agnostic-parallel-backend-for-quantum-simulations/#introducing-lightningkokkos) when on CPU using OpenMP.
- Available on Linux and MacOS.  

<br/>Use <code>lightning.kokkos</code> if you are an experienced user who wants high-performance simulation. 
`,
      },
      {
        title: 'lightning.gpu',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/performance/process/lightning.gpu.png',
        description: `[<code>lightning.gpu</code>](https://docs.pennylane.ai/projects/lightning/en/stable/lightning_gpu/device.html) connects PennyLane to your NVIDIA GPUs, allowing you to scale to larger circuits beyond 20 qubits.
- Statevector simulator using NVIDIA’s [cuQuantum SDK](https://developer.nvidia.com/cuquantum-sdk).
- Fast gradients using the [adjoint differentiation method](https://pennylane.ai/qml/demos/tutorial_adjoint_diff/).
- Gradient calculations can be parallelized.
- Multi-GPU and multi-node [support](https://pennylane.ai/blog/2023/09/distributing-quantum-simulations-using-lightning-gpu-with-NVIDIA-cuQuantum/) using MPI.  

<br/>Don’t have NVIDIA GPUs? No problem—head on over to our <code>lightning.kokkos</code> device.
`,
      },
      {
        title: 'lightning.tensor',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/performance/process/lightning.tensor.png',
        description: `[<code>lightning.tensor</code>](https://docs.pennylane.ai/projects/lightning/en/stable/lightning_tensor/device.html) allows PennyLane to leverage the power of tensor networks on GPU to speed up your simulations.
- Tensor network simulator using NVIDIA's [cuQuantum SDK](https://developer.nvidia.com/cuquantum-sdk).
- Trade off between simulation accuracy and speed by adjusting the bond dimension.
- Quantum circuit gradients using the [parameter-shift method](https://docs.pennylane.ai/en/stable/code/api/pennylane.gradients.param_shift.html).

<br/>Don’t have NVIDIA GPUs? No problem—head on over to our <code>lightning.kokkos</code> device.
`,
      },
    ],
  },
  contact_section: {
    title: 'Get in touch',
    description:
      'Integrating PennyLane can bring HPC to the forefront of quantum computing. Reach out and we’ll show you how.',
    submit_button: {
      label: 'Submit', // Limit to 20 characters including spaces - Defaults to 'Submit'
    },
    footnote: `By submitting you’re agreeing to our [privacy policy](https://xanadu.ai/privacy).`, // Markdown syntax can be used here (supports regular text, and anchors/links)
    success_description: 'A PennyLane member will reach out shortly.',
    success_title: 'Thank you!',
    error_message: 'Something went wrong. Please try again later.',
  },
}
